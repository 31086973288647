a:link {
  color: red;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: black;
}
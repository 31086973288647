.aboutContainer {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: 'Courier New';
    font-size: 40px;
    color: rgba(0, 0, 0, 1);
    
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0);
  }

@media screen and (max-width: 768px) {
    .aboutContainer {
      top: 30%;
      font-size: 30px;
      left: 0%;
      right: 0%;
    }
}

.aboutButton {
  position: absolute;
  z-index: 999;
  top: 5%;
  right: 5%;
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font-family: 'Courier New';
  font-size: 30px;
	cursor: pointer;
	outline: inherit;
}

.aboutButton:hover {
  color: red;
}